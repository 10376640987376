@import ../../../node_modules/bulma-checkradio/dist/css/bulma-checkradio.min.css
@import ../../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css
$mobile-breakpoint: 500px
$tablet-breakpoint: 768px
$desktop-breakpoint: 1023px
$border-radius: 10px
$highlight-colour: #ef8f0e

body
  padding: 15px 10px
  background-color: #f8f6f6
  min-height: 100vh
  overflow: hidden

.chart
  width: 300px
  max-width: 100%
  margin-bottom: 10px

.more-info-body
  p
    margin-bottom: 10px

.sample-chart
  margin-top: 10px
  margin-right: 20px

.about-bar
  text-align: right

  .button
    margin-left: 5px

.contact-link
  margin-right: 5px
  font-size: 0.75rem

.search-box
  margin-top: 30px
  margin-bottom: 15px

.input:focus
  border-color: $highlight-colour

.control.is-loading::after
  border-bottom-color: $highlight-colour
  border-left-color: $highlight-colour
  right: 2em

.clear-search
  position: absolute
  top: 0.625em
  right: 0.625em
  z-index: 4
  cursor: pointer

.replace-game
  margin-left: 5px
  margin-right: 10px

[data-tooltip]
    cursor: inherit

.centre-hex
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    font-weight: bold
    text-shadow: 0 0 3px #000

.react-autosuggest__container
  display: block
  position: relative
  z-index: 3

  .input
    padding-right: 3em

    &:focus
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      box-shadow: none

  .react-autosuggest__suggestions-list
    position: absolute
    width: 100%
    border: 1px solid #ccc
    border-top: none
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    background-color: #fff

  .item
    padding: 2px 5px
    display: flex
    align-items: center

    &:not(:last-child)
      margin-bottom: 5px

    &.highlighted
      background-color: $highlight-colour
      color: #fff
      cursor: pointer

  .image
    margin-right: 10px

  .game-image
    width: 100%
    height: 100%
    background-size: cover
    background-position: center

  .name-suggestion
    display: flex
    flex-wrap: wrap

  .primary-name
    margin-right: 0.3em

  .alternative-name
    color: #777

.filter-box
  position: relative
  padding-right: 0
  top: 10px
  display: flex
  max-height: calc(100vh - 20px)
  transition: all .36s ease-in-out
  transform: translate3d(0, 0, 0)
  z-index: 1

.filter-column
  &.is-one-third-tablet
    @media (max-width: $tablet-breakpoint)
      .filter-box
        width: 80vw
        &.open
          transform: translate3d(-80vw, 0, 0) translate3d(45px, 0, 0)
        .mobile-filter-button
          display: flex
  &.is-one-third-desktop
    @media (max-width: $desktop-breakpoint)
      .filter-box
        width: 80vw
        &.open
          transform: translate3d(-80vw, 0, 0) translate3d(45px, 0, 0)
        .mobile-filter-button
          display: flex

.filter-container
  display: flex
  flex: 1 1 auto
  flex-direction: column
  max-height: 100%
  overflow: hidden

.range-input
  display: flex
  align-items: flex-end

  @media (max-width: $desktop-breakpoint)
    flex-direction: column
    align-items: stretch

  > div
    width: 50%
    flex: 1 1 auto
    @media (max-width: $desktop-breakpoint)
      width: auto

    &:not(:last-child)
      margin-right: 10px
      @media (max-width: $desktop-breakpoint)
        margin-right: 0


.mobile-filter-button
  display: none
  cursor: pointer
  flex: 0 0 auto
  justify-content: center
  align-items: center
  width: 45px
  padding: 0 10px
  margin-left: -20px

.mobile-filter-button-text
  transform: rotate(-90deg)
  font-size: 1.5rem
  white-space: nowrap
  > *
      margin: 0 5px

.filter-subcontainer
  padding-right: 1.25rem

.filter-options
  flex: 0 1 auto
  margin-bottom: 0.75rem
  overflow-y: auto

.checkbox-option
  margin-bottom: 0.75rem

.clear-filter-button
  margin-bottom: 10px

.filter-button
  flex: 0 0 auto

.games-column.column.is-two-thirds-tablet.is-full-mobile
  @media (max-width: $tablet-breakpoint)
    width: calc(100% - 50px)

.games-column.column.is-two-thirds-desktop.is-full-mobile
    @media (max-width: $desktop-breakpoint)
        width: calc(100% - 50px)

.recommendations-heading
  margin-top: 15px
  margin-bottom: 30px
  display: flex
  justify-content: space-between

.try-these-heading
  margin-right: 5px

.info-button
  align-self: flex-end
  margin-bottom: 5px

.glossary
  dt
    font-weight: bold

  dd
    margin-inline-start: 40px
    margin-bottom: 10px

.selected-game
  display: flex
  flex-wrap: wrap

.time-trend-heading
  margin-top: 6px

.game
  display: flex
  position: relative
  margin-bottom: 15px

  .image-background
    position: absolute
    width: 200px
    height: 150px
    overflow: hidden
    border-radius: $border-radius
    @media (max-width: $mobile-breakpoint)
      display: none

  .image-background-image
    margin: -5px
    width: 210px
    height: 160px
    background-position: center
    background-size: cover
    filter: blur(6px)
    @media (max-width: $mobile-breakpoint)
      display: none

  .image-container
    flex: 0 0 auto
    z-index: 1
    width: 200px
    height: 150px
    margin-right: 15px
    display: flex
    align-items: center
    justify-content: center
    align-self: flex-start
    overflow: hidden
    border-radius: $border-radius
    @media (max-width: $mobile-breakpoint)
      align-items: flex-start
      width: 100px
      height: auto

  .game-information
    flex: 1 1 auto
    margin-right: 10px

  .game-image
    position: relative
    max-height: 100%

  .game-name
    font-size: 22px
    overflow-wrap: break-word

  .star-stack
    vertical-align: text-top
    width: 1.5em
    height: 1.5em
    line-height: 1.5em

  .filled-star
    color: gold

  .empty-star
    color: white

  .score-hex
    fill: green

  .game-score
    fill: #fff
    text-anchor: middle

  .game-sub-score
    font-size: 0.75em

  .game-breakdown
    display: flex
    flex-wrap: wrap

  .game-scores
    flex: 1 1 200px
    max-width: 200px
    margin-bottom: 10px

  .game-stars
    margin-right: 15px
    margin-bottom: 10px

  .game-stats
    display: flex
    flex-wrap: wrap
    justify-content: center

  .game-stat
    text-align: center
    margin-right: 10px
    margin-bottom: 10px

@media (max-width: $mobile-breakpoint)
  .title.is-1
    font-size: 2.25rem

.ranking-heading
  margin-bottom: 30px

.ranking.game
  .image-background
    width: 160px
    height: 120px

  .image-background-image
    width: 170px
    height: 130px

  .image-container
    width: 160px
    height: 120px
    @media (max-width: $mobile-breakpoint)
      align-items: flex-start
      width: 100px
      height: auto

  .rank-rating-image
    display: flex
    @media (max-width: $desktop-breakpoint)
      flex-direction: column-reverse

  .ranks
    margin-right: 10px

  .ratings
    margin-right: 10px

  .ttg-rank
    margin-top: 10px
    display: flex
    width: 34px
    height: 34px
    align-items: center
    justify-content: center
    color: #fff
    font-weight: bold
    text-shadow: 0 0 3px #000
    background-size: 100%
    &::before
      font-weight: normal

  .bgg-rank
    margin-top: 10px
    display: flex
    width: 34px
    height: 50px
    align-items: center
    justify-content: center
    color: #fff
    font-weight: bold
    text-shadow: 0 0 3px #000
    background-size: auto 100%
    &::before
        font-weight: normal

  .ttg-rating
    margin-top: 9px

  .geek-rating
    margin-top: 14px

  .game-stats
    margin-top: 9px

  .chart
    width: 250px
    min-width: 250px
    @media (max-width: $tablet-breakpoint)
      display: none

  .filter-box
    @media (max-width: $desktop-breakpoint)
      width: 80vw
      &.open
        transform: translate3d(-80vw, 0, 0) translate3d(45px, 0, 0)
